import React from "react";
import CareersMember from "./components/CareersMember";
import Career from "./components/Career";
import Dropdown from "./components/Dropdown";
const Careers = () => {
  return (
    <div className="flex flex-col gap-y-[30px]">
      <CareersMember/>
      <Dropdown/>
      <Career/>
    </div>
  );
};

export default Careers;
