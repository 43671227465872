import { useEffect, useState } from "react";
import "../index.css";
import { add, edit, getAll } from "../../../controllers/Home/TagLine";

const WrapperId = "TagLineFormWrapper";
const FormId = "TagLineForm";
let toggleForm = () => {
  document.getElementById(WrapperId).classList.toggle("hide");
  document.getElementById(FormId).reset();
};
let formSubmitHandle = async (e) => {
  e.preventDefault();
  return add(e.target.line1.value, e.target.line2.value);
};
let formEditHandle = async (id, e) => {
  e.preventDefault();
  let res = await edit(id, e.target.line1.value, e.target.line2.value);
  return res;
};
const TagLineForm = () => {
  let [tagLine, setTagline] = useState({
    line1: "",
    line2: "",
  });

  useEffect(() => {
    setTimeout(() => {
      getAll().then((res) => {
        setTagline(res?.data[0]);
      });
    }, 20);
  }, []);
  return (
    <>
      {tagLine && (
        <div>
          <h1 className="text-black ">Tagline</h1>

          <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th className="w-[10%]">Line</th>
                <th>Text</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Line1</td>
                <td>
                  <p>{tagLine?.line1}</p>
                </td>
              </tr>
              <tr>
                <td>Line2</td>
                <td>{tagLine?.line2}</td>
              </tr>
            </tbody>
          </table>
          <div
            className="flex justify-center items-center bg-[#254fc4] text-white h-[35px] cursor-pointer hover:bg-[#8445c3]"
            onClick={toggleForm}
          >
            Edit
          </div>
        </div>
      )}
      <Form tagLine={tagLine} callback={setTagline} />
    </>
  );
};
let Form = ({ tagLine, callback }) => {
  let [loading, setLoading] = useState(false);
  return (
    <div
      id={WrapperId}
      className={"FormWrapper " + `${!tagLine ? " " : "hide"}`}
      onClick={toggleForm}
    >
      <form
        onClick={(e) => {
          e.stopPropagation();
        }}
        id={FormId}
        className="Form"
        onSubmit={(e) => {
          formEditHandle(tagLine._id, e).then((res) => {
            if (res) {
              toggleForm();
              setTimeout(() => {
                getAll().then((res) => {
                  callback(res?.data[0]);
                });
              }, 20);
            }
          });
        }}
      >
        <div
          className="closeFormBtn"
          onClick={(e) => {
            e.stopPropagation();
            toggleForm();
          }}
        >
          <img src="/asset/close.png" width={20} alt="Close" />
        </div>
        <label>First Line</label>
        <input type="text" name="line1" defaultValue={tagLine?.line1} />
        <label>Second Line</label>
        <input type="text" name="line2" defaultValue={tagLine?.line2} />

        <div className="wrap-submit">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
};
export default TagLineForm;
