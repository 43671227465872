import { useEffect, useState } from "react";
import { add, edit } from "../../../controllers/Solution/Solution";
import { getAll } from "../../../controllers/Solution/SolutionGroup";
import { imgSource } from "../../../controllers/API_Object";
// import "../index.css";
const SolutionForm = ({ data, refresh, gid,clear }) => {
  let [groupId, setGroupId] = useState(gid);
  let [image, setImage] = useState();
  let [gdata, setGroupdata] = useState();

  if (!gdata) {
    setTimeout(() => {
      getAll().then((res) => {
        setGroupdata(res?.data);
      });
    }, 20);
  }

  if (!groupId) {
    setGroupId(gid);
  }

  useEffect(() => {
    if (data) {
      if (data.image) {
        setImage(imgSource + data?.image);
      }
      setGroupId(data?.groupId);
    }
  }, [data]);

  const WrapperId = "SolutionFormWrapper" + gid;
  const FormId = "SolutionForm" + gid;
  let toggleForm = () => {
    setImage();
    document.getElementById(WrapperId).classList.toggle("hide");
    document.getElementById(FormId).reset();
    setGroupdata();
    clear()
  };
  let formSubmitHandle = (e) => {
    e.preventDefault();
    let sectionId = document.getElementById("SolutionGroupSection" + gid).value;
    let formData = new FormData();
    formData.append("file", e.target.img.files[0]);
    add(
      formData,
      sectionId,
      e.target.topic.value,
      e.target.target.value,
      e.target.description.value
    ).then(() => {
      refresh();
      toggleForm();
    });
  };
  let formEditHandle = (e) => {
    e.preventDefault();
    let sectionId = groupId;
    let formData = e.target.img.files[0] ? new FormData() : undefined;
    formData?.append("file", e.target.img.files[0]);
    edit(
      data._id,
      formData,
      sectionId,
      e.target.topic.value,
      e.target.target.value,
      e.target.description.value,
      data
    ).then(() => {
      refresh();
      toggleForm();
    });
  };
  return (
    <>
      <div
        className="flex justify-center items-center bg-[#254fc4] text-white h-[35px] cursor-pointer hover:bg-[#8445c3]"
        onClick={() => {
          document.getElementById(WrapperId).classList.toggle("hide");
        }}
      >
        Add Solution
      </div>
      <div
        id={WrapperId}
        className={"FormWrapper " + (data ? "" : "hide")}
        onClick={toggleForm}
      >
        <form
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={FormId}
          className="Form"
          onSubmit={(e) => {
            if (!!data) {
              formEditHandle(e);
            } else formSubmitHandle(e);
          }}
        >
          <div
            className="closeFormBtn"
            onClick={(e) => {
              e.stopPropagation();
              toggleForm();
            }}
          >
            <img src="/asset/close.png" width={20} alt="Close" />
          </div>
          <label>Section</label>
          <select
            name={"SolutionGroupSection" + gid}
            id={"SolutionGroupSection" + gid}
            form={FormId}
            value={groupId}
            onChange={(e) => {
              setGroupId(e.target.value);
            }}
          >
            {!!gdata &&
              gdata.map((e) => (
                <option key={e._id} value={e._id}>
                  {e.topic}
                </option>
              ))}
          </select>
          <br />
          <label>Image</label>
          {image && <img src={image} alt="" className="h-[150px]" />}
          <br />
          <input
            required={!data ? true : false}
            type="file"
            name="img"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              setImage(URL.createObjectURL(e.target.files[0]));
            }}
          />
          <p className="mb-3 text-slate-600">type: png, jpeg</p>

          <label>Topic</label>

          <input
            required={!data ? true : false}
            type="text"
            defaultValue={data ? data.topic : ""}
            name="topic"
          />
          <label>Tag</label>
          <div className="flex flex-col py-2 w-full">
            <img
              src={require("./asset/btn.png")}
              alt=""
              className="w-full max-w-[100%]"
            />
            <div className="flex justify-evenly w-full flex-wrap">
              <p>ShopStrategy</p>
              <p>OnlineMarketing</p>
              <p>Fulfillment</p>
              <p>Streaming</p>
              <p>ShortVideo</p>
            </div>
          </div>
          <input
            type="text"
            defaultValue={data ? data.targetId : ""}
            name="target"
          />
          <p className="mb-3 text-slate-600">
            this is optional for match the button to some section,Enter above
            value for matching it
          </p>

          <label>Desciption</label>
          <input
            required
            type="text"
            defaultValue={data ? data.description : ""}
            name="description"
          />
          <div className="wrap-submit">
            <input type="submit" />{" "}
          </div>
        </form>
      </div>
    </>
  );
};

export default SolutionForm;
