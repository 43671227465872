import { useEffect, useState } from "react";
import "../index.css";
import { edit } from "../../../controllers/Solution/SolutionGroup";
const SolutionGroupForm = ({ id,data, refresh ,clear,color}) => {
  // let [loading, setLoading] = useState(false);
  useEffect(()=>{
  },[data]);
  const WrapperId = "SolutionGroupFormWrapper"+id;
  const FormId = "SolutionGroupForm"+id;
  let toggleForm = () => {
    clear()
    document.getElementById(WrapperId).classList.toggle("hide");
    document.getElementById(FormId).reset();
  };

  let formSubmitHandle = async (e) => {
    e.preventDefault();
    await edit(data._id, e.target.headerText.value, e.target.description.value);
    refresh();
    toggleForm();
  };

  return (
    <>
      <div
        className="w-[50px] bg-[#254fc4] text-white text-center"
        onClick={() => {
          document.getElementById(WrapperId).classList.toggle("hide");
        }}
      >
        Edit
      </div>
      <div id={WrapperId} className={"FormWrapper hide"} onClick={toggleForm}>
        <form
          onClick={(e) => {
            
            e.stopPropagation();
          }}
          id={FormId}
          className="Form"
          style={{backgroundColor:color}}
          onSubmit={(e)=>{
            formSubmitHandle(e)
          }}
        >
          <div
            className="closeFormBtn"
            onClick={(e) => {
              e.stopPropagation();
              toggleForm();
            }}
          >
            <img src="/asset/close.png" width={20} alt="Close" />
          </div>
          <label>Header Text</label>
          <input type="text" name="headerText" defaultValue={data?.topic} />
          <label>Description</label>
          <textarea
            className="w-[100%] ring-1 ring-[#254fc4] rounded-[5px] resize-none p-1"
            type="text"
            name="description"
            defaultValue={data?.description}
          />
          <div className="wrap-submit">
            <input type="submit" />{" "}
          </div>
        </form>
      </div>
    </>
  );
};

export default SolutionGroupForm;
