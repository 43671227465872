import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Home.partner;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path), { cache: false });
  } catch (error) {}
};
export let add = async (file1, file2, width, height) => {
  try {
    let img1 = await axios.post(endpointBuilder("/upload"), file1, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    let img2 = await axios.post(endpointBuilder("/upload"), file2, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    try {
      return await axios.post(endpointBuilder(path), {
        variant1: img1.data.file,
        variant2: img2.data.file,
        width,
        height,
      });
    } catch (error) {}
  } catch (error) {}
};
export let edit = async (id, file1, file2, width, height, old) => {
  try {
    let img1 = file1
      ? await axios
          .post(endpointBuilder("/upload"), file1, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (res) => {
            await axios.delete(endpointBuilder("/file/" + old.variant1));
            return res
          })
      : undefined;
    let img2 = file2
      ? await axios
          .post(endpointBuilder("/upload"), file2, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (res) => {
            await axios.delete(endpointBuilder("/file/" + old.variant2));
            return res

          })
      : undefined;
    try {
      let data = {
        id,
        variant1: img1?.data.file,
        variant2: img2?.data.file,
        width,
        height,
      };
      return await axios.patch(endpointBuilder(path), data);
    } catch (error) {}
  } catch (error) {}
};

export let remove = async (id, index, datas) => {
  try {
    let item = "";
    for await (let data of datas) {
      if (data.index > index) {
        await axios.patch(endpointBuilder(path), {
          id: data._id,
          index: data.index - 1,
        });
      } else if (data.index === index) {
        item = data;
      }
    }
    await axios.delete(endpointBuilder("/file/" + item.variant1));
    await axios.delete(endpointBuilder("/file/" + item.variant2));
    return await axios.delete(endpointBuilder(path + "?id=" + id));
  } catch (error) {}
};

export let onSave = async (datas) => {
  try {
    for await (let data of datas) {
      await axios.patch(endpointBuilder(path), {
        id: data._id,
        index: data.index,
      });
    }
  } catch (error) {}
};
