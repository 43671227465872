import React, { useState } from "react";

import DepartmentForm from "./DepartmentForm";
import { getAll, remove } from "../../../controllers/Careers/Department";
export const Department = () => {
  let [edit, setEdit] = useState();
  let [data, setData] = useState();

  if (!data) {
    setTimeout(() => {
      getAll().then((res) => {
        setData(res?.data);
      });
    }, 20);
  }
  let refresh = () => {
    setEdit();
    setData();
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Department</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((e) => (
              <tr key={e._id}>
                <td>{e.type}</td>
                <td>
                  <div className="flex gap-x-[15px] justify-end">
                    <button
                      onClick={() => {
                        setEdit(e);
                      }}
                    >
                      <img
                        className="object-cover"
                        src="/asset/pencil.png"
                        alt="Edit"
                        width={20}
                      />
                    </button>
                    <button
                      onClick={() => {
                        remove(e._id).then(() => {
                          refresh();
                        });
                      }}
                    >
                      <img src="/asset/bin.png" alt="Delete" width={20} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <DepartmentForm refresh={refresh} data={edit} />
    </div>
  );
};
