import { useState } from "react";
import { edit } from "../../../controllers/Contact/Addr";
// import "../index.css";
const AddrForm = ({ id, data, refresh }) => {
  let [loading, setLoading] = useState(false);
  const WrapperId = "AddrFormWrapper";
  const FormId = "AddrForm";
  let toggleForm = () => {
    refresh();
    document.getElementById(WrapperId).classList.toggle("hide");
    document.getElementById(FormId).reset();
  };
  let formSubmitHandle = (e) => {
    e.preventDefault();
    edit(
      id,
      e.target.Building.value,
      e.target.Address.value,
      e.target.Email.value,
      e.target.Tel.value,
      e.target.map.value
    ).then(() => {
      toggleForm();
    });
  };
  return (
    <>
      <div
        className="addBtn flex justify-center items-center bg-[#254fc4] text-white h-[35px] cursor-pointer hover:bg-[#8445c3]"
        onClick={() => {
          document.getElementById(WrapperId).classList.toggle("hide");
        }}
      >
        Edit Adress
      </div>
      <div id={WrapperId} className="FormWrapper hide" onClick={toggleForm}>
        <form
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={FormId}
          className="Form"
          onSubmit={formSubmitHandle}
        >
          <div
            className="closeFormBtn"
            onClick={(e) => {
              e.stopPropagation();
              toggleForm();
            }}
          >
            <img src="/asset/close.png" width={20} alt="Close" />
          </div>
          <label>Building</label>
          <input
            type="text"
            name="Building"
            required
            defaultValue={data?.building}
          />
          <label>Address</label>
          <input
            type="text"
            name="Address"
            required
            defaultValue={data?.address}
          />
          <label>Email</label>
          <input
            type="text"
            name="Email"
            required
            defaultValue={data?.email}
          />
          <label>Tel</label>
          <input
            type="text"
            name="Tel"
            required
            defaultValue={data?.tel}
          />
          <label>Google map</label>
          <input
            type="text"
            name="map"
            // required
            defaultValue={data?.source}
          />
          <div className="wrap-submit">
            <input type="submit" />{" "}
          </div>
        </form>
      </div>
    </>
  );
};

export default AddrForm;
