import React, { useEffect, useState } from "react";
import { getByGroupID, remove } from "../../../controllers/Solution/Solution";
import SolutionForm from "./SolutionForm";
import { imgSource } from "../../../controllers/API_Object";
const SolutionTable = ({ gid ,clear}) => {
  let [data, setData] = useState();
  let [editData, setEditData] = useState();
  let [collapes, setCollaps] = useState(false);
  useEffect(() => {
    if (!data) {
      setTimeout(() => {
        getByGroupID(gid).then((res) => {
          let data = res?.data.map((data) => {
            data.expend = false;
            return data;
          });
          setData(data);
        });
      }, 50);
    }
  }, [data]);

  let refresh = () => {
    clear()
    setEditData();
    setData();
  };
  return (
    <div id={"solution" + gid}>
      <div className="flex justify-end px-3">
        <button
          onClick={() => {
            setCollaps(!collapes);
          }}
          className="px-3 py-1 mb-1 bg-[#254fc4] text-white text-center"
        >
          {collapes ? "Expand" : "Collapes"}
        </button>
      </div>
      <table className="table-solution" id={gid}>
        <thead>
          <tr>
            <th>Image</th>
            <th className="w-[25%]">Topic</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody style={{ display: collapes ? "none" : "table-row-group" }}>
          {!!data &&
            data?.map((e, i) => (
              <tr
                key={i}
                onClick={() => {
                  setData(
                    data?.map((item) => {
                      if (e._id === item._id) {
                        item.expend = !item.expend;
                      }
                      return item;
                    })
                  );
                }}
              >
                <td>
                  <img style={{display:e.expend?"":"none"}} src={`${imgSource}${e.image}`} alt={e.image} />
                </td>
                <td>{e.topic}</td>
                <td>{e.expend && e.description}</td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="flex gap-x-[15px] justify-end">
                    <button
                      onClick={() => {
                        setEditData(e);
                      }}
                    >
                      <img
                        className="object-cover"
                        src="/asset/pencil.png"
                        alt="Edit"
                        width={20}
                      />
                    </button>
                    <button
                      onClick={() => {
                        remove(e._id, e.image).then(() => {
                          refresh();
                        });
                      }}
                    >
                      <img src="/asset/bin.png" alt="Delete" width={20} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <SolutionForm gid={gid} data={editData} refresh={refresh} clear={()=>{setEditData()}} />
    </div>
  );
};

export default SolutionTable;
