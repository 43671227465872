import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Careers.careers;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path), { cache: false });
  } catch (error) {}
};
export let add = async (
  job,
  cityId,
  departmentId,
  contractId,
  amount,
  openString,
  closeString,
  responsibilityDescription,
  responsibilityList,
  basicQualification
) => {
  try {
    return await axios.post(endpointBuilder(path), {
      job,
      cityId,
      departmentId,
      contractId,
      amount,
      openString,
      closeString,
      responsibilityDescription,
      responsibilityList,
      basicQualification,
    });
  } catch (error) {}
};
export let edit = async (
  id,
  job,
  cityId,
  departmentId,
  contractId,
  amount,
  openString,
  closeString,
  responsibilityDescription,
  responsibilityList,
  basicQualification
) => {
  try {
    return await axios.patch(endpointBuilder(path), {
      id,
      job,
      cityId,
      departmentId,
      contractId,
      amount,
      openString,
      closeString,
      responsibilityDescription,
      responsibilityList,
      basicQualification,
    });
  } catch (error) {}
};

export let remove = async (id, index, datas) => {
  try {
    for await (let data of datas) {
      if (data.index > index) {
        await axios.patch(endpointBuilder(path), {
          id: data._id,
          index: data.index-1,
        });
      }
    }
    return await axios.delete(endpointBuilder(path + "?id=" + id));
  } catch (error) {}
};
export let onSave = async (datas) => {
  try {
    for await (let data of datas) {
      await axios.patch(endpointBuilder(path), {
        id: data._id,
        index: data.index,
      });
    }
  } catch (error) {}
};