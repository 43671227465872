import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Home.homeTagLine;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path),{ cache: false });
  } catch (error) {
    return;
  }
};
export let add = async (line1, line2) => {
  let data = { line1, line2 };
  try {
    return await axios.post(endpointBuilder(path), data);
  } catch (error) {
    return;
  }
};
export let edit = async (id, line1, line2) => {
  try {
    let res = await axios.patch(endpointBuilder(path), { id, line1, line2 });
    return true;
  } catch (error) {
    return false
  }
};

export let remove = async (id) => {
  try {
    return await axios.delete(endpointBuilder(path), { data: { id } });
  } catch (error) {}
};
