import { useEffect, useState } from "react";
import SolutionTable from "./Solution";
import SolutionGroupForm from "./SolutionGroupForm";

export const SolutionGroup = ({ data, refresh }) => {
  let [editData, setEditadata] = useState();
  useEffect(() => {}, [editData]);

  return (
    <div className=" flex flex-col gap-y-[20px]">
      <div>
        <div className="my-5">
          <h1 className="text-black">{data.topic}</h1>
          <h3 className="px-12 text-center">{data.description}</h3>
          <div
            className="flex justify-center"
            onClick={() => {
              setEditadata(data);
            }}
          >
            <SolutionGroupForm
              id={data?._id}
              key={data?._id}
              refresh={refresh}
              clear={() => {
                setEditadata();
              }}
              data={editData}
              color={"F0A"}
            />
          </div>
        </div>
        <div>
          <SolutionTable
            key={data._id}
            gid={data._id}
            clear={() => {
              refresh();
            }}
          />
        </div>
      </div>
    </div>
  );
};
