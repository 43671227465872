import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Careers.member;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path), { cache: false });
  } catch (error) {}
};
export let add = async (file, name, qoute, role) => {
  try {
    await axios
      .post(endpointBuilder("/upload"), file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        if (!!res?.data) {
          try {
            let r= await axios.post(endpointBuilder(path), {
              profile: res.data.file,
              name,
              qoute,
              role,
            });
            return r
          } catch (error) {
          }
        }
      });
  } catch (error) {}
};
export let edit = async (id, file, name, qoute, role, old) => {
  try {
    let res = file
      ? await axios
          .post(endpointBuilder("/upload"), file, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (res) => {
            await axios.delete(endpointBuilder("/file/" + old.profile));
            return res;
          })
      : undefined;
    if (res || !file) {
      try {
        return await axios.patch(endpointBuilder(path), {
          id,
          profile: res?.data.file,
          name,
          qoute,
          role,
        });
      } catch (error) {}
    }
  } catch (error) {}
};

export let remove = async (id, index, datas) => {
  try {
    let item = "";

    for await (let data of datas) {
      if (data.index > index) {
        await axios.patch(endpointBuilder(path), {
          id: data._id,
          index: data.index - 1,
        });
      } else if (data.index === index) {
        item = data;
      }
    }
    await axios.delete(endpointBuilder("/file/" + item.profile));
    return await axios.delete(endpointBuilder(path + "?id=" + id));
  } catch (error) {}
};
export let onSave = async (datas) => {
  try {
    for await (let data of datas) {
      await axios.patch(endpointBuilder(path), {
        id: data._id,
        index: data.index,
      });
    }
  } catch (error) {}
};
