import { useEffect, useState } from "react";
import "../index.css";
import { add, edit } from "../../../controllers/Careers/Member";
import { imgSource } from "../../../controllers/API_Object";
const CareersMemberForm = ({ data, refresh, index }) => {
  // let [loading, setLoading] = useState(false);
  let [image, setImage] = useState();
  useEffect(() => {
    if (data) {
      setImage(imgSource + data?.profile);
    }
  }, [data]);
  const WrapperId = "CareersMemberFormWrapper";
  const FormId = "CareersMemberForm";
  let toggleForm = () => {
    refresh();
    setImage()
    document.getElementById(WrapperId).classList.toggle("hide");
    document.getElementById(FormId).reset();
  };
  let formSubmitHandle = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.img.files[0]);
    add(formData, e.target.name.value,e.target.qoute.value, e.target.role.value).then(() => {
      refresh(() => undefined);
      toggleForm();
    });
  };
  let formEditHandle = async (e) => {
    e.preventDefault();
    let formData =e.target.img.files[0]? new FormData():undefined;
    formData?.append("file", e.target.img.files[0]);
    await edit(data?._id, formData, e.target.name.value,e.target.qoute.value ,e.target.role.value,data);
    toggleForm();
  };
  return (
    <>
      <div
        className="flex justify-center items-center bg-[#254fc4] text-white h-[35px] cursor-pointer hover:bg-[#8445c3]"
        onClick={() => {
          document.getElementById(WrapperId).classList.toggle("hide");
        }}
      >
        Add Member
      </div>
      <div
        id={WrapperId}
        className={"FormWrapper " + `${data ? " " : "hide"}`}
        onClick={toggleForm}
      >
        <form
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={FormId}
          className="Form"
          onSubmit={(e) => {
            if (data) {
              formEditHandle(e);
            } else formSubmitHandle(e);
          }}
        >
          <div
            className="closeFormBtn"
            onClick={(e) => {
              e.stopPropagation();
              toggleForm();
            }}
          >
            <img src="/asset/close.png" width={20} alt="Close" />
          </div>
          <label>Profile</label>

          {image && <img src={image} alt="" className="h-[150px]" />}
          <input
            type="file"
            name="img"
            accept="image/png, image/jpeg"
            required={!data ? true : false}
            onChange={(e) => {
              setImage(URL.createObjectURL(e.target.files[0]));
            }}
          />
          <p className="text-slate-600">type: png, jpeg</p>

          <label>Name</label>
          <input type="text" required name="name" defaultValue={data?.name} />
          <label>Role</label>
          <input type="text" required name="role" defaultValue={data?.role} />
          <label>Quote</label>
          <input type="text" name="qoute" defaultValue={data?data?.qoute:""}/>
          <div className="wrap-submit">
            <input type="submit" />{" "}
          </div>
        </form>
      </div>
    </>
  );
};

export default CareersMemberForm;
