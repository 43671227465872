import { useState, useEffect } from "react";
import { onSave, getAll, remove } from "../../../controllers/About/AboutMember";
import AboutMemberForm from "./AboutMemberForm";
import "../index.css";
import { imgSource } from "../../../controllers/API_Object";
const AboutMember = () => {
  let [editData, setEditdata] = useState();
  let [datas, setData] = useState();
  let [index, setIndex] = useState(0);
  let [isChange, setIsChange] = useState(false);

  if (!datas) {
    setTimeout(() => {
      getAll().then((res) => {
        res.data.map((data) => {
          data.expand = false;
        });
        setData(res?.data);
      });
    }, 20);
  }
  useEffect(() => {
    setIndex(datas?.length);
  }, [datas]);
  let refresh = () => {
    setEditdata();
    setData();
  };
  return (
    <div>
      <h1 className="text-black ">Members</h1>
      <div className="flex justify-end px-4 mb-2 gap-x-1">
        <button
          onClick={() => {
            onSave(datas).then(() => {
              setIsChange(false);
              refresh();
            });
          }}
          disabled={!isChange}
          className="w-[65px] h-[40px] bg-[#c47a25] text-white text-center disabled:opacity-50"
        >
          Save
        </button>
        <button
          onClick={() => {
            setIsChange(false);
            refresh();
          }}
          disabled={!isChange}
          className="w-[65px] h-[40px] bg-[#bd1717] text-white text-center disabled:opacity-50"
        >
          Cancel
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Profile</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Role</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!datas &&
            datas
              ?.sort((e1, e2) => {
                return e1.index - e2.index;
              })
              .map((member, i) => {
                return (
                  <tr
                    id={"aboutMem" + i}
                    key={i}
                    onClick={(e) => {
                      setData(
                        datas.map((member, i2) => {
                          if (i === i2) {
                            member.expand = !member?.expand;
                          }
                          return member;
                        })
                      );
                    }}
                  >
                    {member?.expand ? (
                      <td>
                        <img
                          src={`${imgSource}${member.profile}`}
                          alt={member.profile}
                        />
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td>{member?.name}</td>
                    <td>{member?.surname}</td>
                    <td>{member?.role}</td>
                    {member?.expand ? (
                      <td>{member?.description}</td>
                    ) : (
                      <td></td>
                    )}
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="flex gap-x-[15px] justify-end">
                        <select
                          value={member?.index}
                          onChange={(e) => {
                            let prev = member.index;
                            let next = parseInt(e.target.value);
                            if (prev - next === 1 || prev - next === -1) {
                              setData(
                                datas.map((e) => {
                                  if (e.index === prev) {
                                    e.index = next;
                                  } else if (e.index === next) {
                                    e.index = prev;
                                  }
                                  return e;
                                })
                              );
                            } else {
                              if (prev > next) {
                                setData(
                                  datas.map((e) => {
                                    if (e.index < prev && e.index >= next) {
                                      e.index++;
                                    } else if (e.index === prev) {
                                      e.index = next;
                                    }
                                    return e;
                                  })
                                );
                              } else if (prev < next) {
                                setData(
                                  datas.map((e) => {
                                    if (e.index <= next && e.index > prev) {
                                      e.index--;
                                    } else if (e.index === prev) {
                                      e.index = next;
                                    }
                                    return e;
                                  })
                                );
                              }
                            }
                            setIsChange(true);
                          }}
                          name=""
                          id=""
                          className="bg-transparent w-[45px]"
                        >
                          {Array.from({ length: index }, (_, i) => i + 1).map(
                            (e) => (
                              <option
                                key={e}
                                value={e}
                                className="bg-white text-black"
                              >
                                {e}
                              </option>
                            )
                          )}
                        </select>
                        <button
                          onClick={(e) => {
                            setEditdata(member);
                          }}
                        >
                          <img
                            className="object-cover"
                            src="/asset/pencil.png"
                            alt="Edit"
                            width={20}
                          />
                        </button>
                        <button
                          onClick={() => {
                            remove(member._id, member.index, datas).then(() => {
                              refresh();
                            });
                          }}
                        >
                          <img src="/asset/bin.png" alt="Delete" width={20} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
      <AboutMemberForm data={editData} index={index} refresh={refresh} />
    </div>
  );
};

export default AboutMember;
