import React from "react";
import AddrForm from "./components/AddrForm"
import Addr from "./components/Addr";
const Contact = () => {
  return (
    <div>
      <Addr/>
    </div>
  );
};

export default Contact;
