import {useState} from "react";
import { SolutionGroup } from "./components/SolutionGroup";
import { getAll } from "../../controllers/Solution/SolutionGroup";

const Solution = () => {
  let [data, setData] = useState();
  if (!data) {
    setTimeout(() => {
      getAll().then((res) => {
        setData(res?.data);
      });
    }, 50);
  }
  let refresh = () => {
    setData()
  };
  return (
    <div>
      {!!data &&
        data.map((data, i) => <SolutionGroup key={i} refresh={refresh} data={data}/>)}
      
    </div>
  );
};

export default Solution;
