import "../index.css";
import { add, edit } from "../../../controllers/Careers/City";
const CityForm = ({ data, refresh }) => {
  const WrapperId = "CityFormWrapper";
  const FormId = "CityForm";
  let toggleForm = () => {
    refresh();
    document.getElementById(WrapperId).classList.toggle("hide");
    document.getElementById(FormId).reset();
  };
  let formSubmitHandle = (e) => {
    e.preventDefault();
    add(e.target.City.value).then((res) => {
      toggleForm();
    });
  };
  let formEditHandle = (e) => {
    e.preventDefault();
    edit(data._id, e.target.City.value).then((res) => {
      toggleForm();
    });
  };
  return (
    <>
      <div
        className="flex justify-center items-center bg-[#254fc4] text-white h-[35px] cursor-pointer hover:bg-[#8445c3]"
        onClick={() => {
          document.getElementById(WrapperId).classList.toggle("hide");
        }}
      >
        Add City
      </div>
      <div
        id={WrapperId}
        className={"FormWrapper " + `${data ? " " : "hide"}`}
        onClick={toggleForm}
      >
        <form
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={FormId}
          className="Form"
          onSubmit={(e) => {
            if (data) {
              formEditHandle(e);
            } else formSubmitHandle(e);
          }}
        >
          <div
            className="closeFormBtn"
            onClick={(e) => {
              e.stopPropagation();
              toggleForm();
            }}
          >
            <img src="/asset/close.png" width={20} alt="Close" />
          </div>
          <label>City</label>
          <input type="text" name="City" defaultValue={data?.name} />
          <div className="wrap-submit">
            <input type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default CityForm;
