import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Solution.solutionGroup;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path),{ cache: false });
  } catch (error) {}
};
export let add = async (topic, description) => {
  try {
    return await axios.post(endpointBuilder(path));
  } catch (error) {}
};
export let edit = async (id, topic, description) => {
  try {
    return await axios.patch(endpointBuilder(path), { id, topic, description });
  } catch (error) {}
};

export let remove = async (id) => {
  try {
    return await axios.delete(endpointBuilder(path));
  } catch (error) {}
};
