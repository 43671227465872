import "./Sidebar.css";
import { Link } from "react-router-dom";
import { GoogleLogout } from "react-google-login";

const Sidebar = ({user}) => {
  const clientID = process.env.REACT_APP_CLIENT_ID
  return (
    <div id="sidebar" className="justify-between">
      <div>
        <div className="text-white text-center font-bold">User:{user}</div>
        <Link to="/home">Home</Link>
        <Link to="/solution">Solution</Link>
        <Link to="/careers">Careers</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
      </div>

      <GoogleLogout
        clientId="577998222775-4smo3pu9292kjlisl6la1i7mm7tkoao0.apps.googleusercontent.com"
        buttonText="Log Out"
        onLogoutSuccess={() => {
          window.location.reload();
        }}
      />
    </div>
  );
};

export default Sidebar;
