import { useState, useEffect } from "react";
import { onSave, getAll, remove } from "../../../controllers/Careers/Careers";
import CareersForm from "./CareersForm";

const Career = () => {
  let [datas, setData] = useState();
  let [editData, setEditdata] = useState();
  let [index, setIndex] = useState(0);
  let [isChange, setIsChange] = useState(false);

  if (!datas) {
    setTimeout(() => {
      getAll().then((res) => {
        res.data.map((data) => {
          data.expand = false;
          return data;
        });
        setData(res?.data);
      });
    }, 20);
  }
  useEffect(() => {
    setIndex(datas?.length);
  }, [datas]);
  let refresh = () => {
    setEditdata();
    getAll().then((res) => {
        res.data.map((data) => {
          data.expand = false;
          return data;
        });
        setData(res?.data);
      });
  };
  return (
    <div>
      <h1 className="text-black ">Careers</h1>
      <div className="flex justify-end px-4 mb-2 gap-x-1">
        <button
          onClick={() => {
            onSave(datas).then(() => {
              setIsChange(false);
              refresh();
            });
          }}
          disabled={!isChange}
          className="w-[65px] h-[40px] bg-[#c47a25] text-white text-center disabled:opacity-50"
        >
          Save
        </button>
        <button
          onClick={() => {
            setIsChange(false);
            refresh();
          }}
          disabled={!isChange}
          className="w-[65px] h-[40px] bg-[#bd1717] text-white text-center disabled:opacity-50"
        >
          Cancel
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th >Job</th>
            <th >Amount</th>
            <th >Open</th>
            <th>Details</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!datas &&
            datas
              ?.sort((e1, e2) => {
                return e1.index - e2.index;
              })
              .map((data) => (
                <tr
                  key={data._id}
                  onClick={() => {
                    setData(() =>
                      datas.map((e) => {
                        if (e._id === data._id) {
                          e.expand = !e.expand;
                        }
                        return e;
                      })
                    );
                  }}
                >
                  <td className="w-[15%]">{data.job}</td>
                  <td>{data.amount}</td>
                  <td>
                    {data.openString}&nbsp;
                    ~&nbsp;
                    {data.closeString}
                  </td>
                  {data?.expand ? (
                    <td className="flex flex-col gap-y-1">
                      <div className="">
                        <div className="bg-[rgba(255,255,255,0.4)] p-1">
                          Responsibility
                        </div>
                      </div>
                      <div>
                        <div>{data.responsibilityDescription}</div>
                        <ul className="list-disc mt-1">
                          {data.responsibilityList.map((data, i) => (
                            <li key={i}>{data}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="">
                        <div className="bg-[rgba(255,255,255,0.4)] p-1">
                          Basic Qualification
                        </div>
                      </div>
                      <div>
                        <ul className="list-disc mt-1">
                          {data.basicQualification.map((data, i) => (
                            <li key={i}>{data}</li>
                          ))}
                        </ul>
                      </div>
                    </td>
                  ) : (
                    <td></td>
                  )}

                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className="flex gap-x-[15px] justify-end">
                      <select
                        value={data.index}
                        onChange={(e) => {
                          let prev = data.index;
                          let next = parseInt(e.target.value);
                          if (prev - next === 1 || prev - next === -1) {
                            setData(
                              datas.map((e) => {
                                if (e.index === prev) {
                                  e.index = next;
                                } else if (e.index === next) {
                                  e.index = prev;
                                }
                                return e;
                              })
                            );
                          } else {
                            if (prev > next) {
                              setData(
                                datas.map((e) => {
                                  if (e.index < prev && e.index >= next) {
                                    e.index++;
                                  } else if (e.index === prev) {
                                    e.index = next;
                                  }
                                  return e;
                                })
                              );
                            } else if (prev < next) {
                              setData(
                                datas.map((e) => {
                                  if (e.index <= next && e.index > prev) {
                                    e.index--;
                                  } else if (e.index === prev) {
                                    e.index = next;
                                  }
                                  return e;
                                })
                              );
                            }
                          }
                          setIsChange(true);
                        }}
                        name=""
                        id=""
                        className="bg-transparent w-[45px]"
                      >
                        {Array.from({ length: index }, (_, i) => i + 1).map(
                          (e) => (
                            <option
                              key={e}
                              value={e}
                              className="bg-white text-black"
                            >
                              {e}
                            </option>
                          )
                        )}
                      </select>
                      <button
                        onClick={(e) => {
                          setEditdata(data);
                        }}
                      >
                        <img
                          className="object-cover"
                          src="/asset/pencil.png"
                          alt="Edit"
                          width={20}
                        />
                      </button>
                      <button
                        onClick={() => {
                          remove(data?._id, data.index, datas).then(() =>
                            refresh()
                          );
                        }}
                      >
                        <img src="/asset/bin.png" alt="Delete" width={20} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
      <CareersForm refresh={refresh} data={editData} />
    </div>
  );
};

export default Career;
