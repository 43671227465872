import { useEffect, useState } from "react";
import "../index.css";
import { add, edit } from "../../../controllers/Careers/Careers";
import { getAll as getCity } from "../../../controllers/Careers/City";
import { getAll as getDepartment } from "../../../controllers/Careers/Department";
import { getAll as getContract } from "../../../controllers/Careers/Contract";
const CareersForm = ({ data, refresh }) => {
  // let [loading, setLoading] = useState(false);
  let [taskList, setTaskList] = useState([]);
  let [qtyList, setQtyList] = useState([]);
  useEffect(() => {
    if (data) {
      setTaskList(data.responsibilityList);
      setQtyList(data.basicQualification);
    }
  }, [data]);
  const WrapperId = "CareersFormWrapper";
  const FormId = "CareersForm";
  let toggleForm = () => {
    refresh();
    setTaskList([]);
    setQtyList([]);
    setCity();
    setDpm();
    setContract();
    document.getElementById(WrapperId).classList.toggle("hide");
    document.getElementById(FormId).reset();
  };
  let formSubmitHandle = (e) => {
    e.preventDefault();
    add(
      e.target.job.value,
      e.target.city.value,
      e.target.department.value,
      e.target.contract.value,
      e.target.Amount.value,
      e.target.Open.value,
      e.target.Close.value,
      e.target.Responsibilities.value,
      taskList,
      qtyList
    ).then(() => {
      toggleForm();
    });
  };
  let formEditHandle = (e) => {
    e.preventDefault();
    edit(
      data._id,
      e.target.job.value,
      e.target.city.value,
      e.target.department.value,
      e.target.contract.value,
      e.target.Amount.value,
      e.target.Open.value,
      e.target.Close.value,
      e.target.Responsibilities.value,
      taskList,
      qtyList
    ).then(() => {
      toggleForm();
    });
  };
  let [city, setCity] = useState();
  let [dpm, setDpm] = useState();
  let [contract, setContract] = useState();

  if (!city) {
    getCity().then((res) => {
      setCity(res.data);
    });
  }
  if (!dpm) {
    getDepartment().then((res) => {
      setDpm(res.data);
    });
  }
  if (!contract) {
    getContract().then((res) => {
      setContract(res.data);
    });
  }
  return (
    <>
      <div
        className="flex justify-center items-center bg-[#254fc4] text-white h-[35px] cursor-pointer hover:bg-[#8445c3]"
        onClick={() => {
          setCity();
          setDpm();
          setContract();
          document.getElementById(WrapperId).classList.toggle("hide");
        }}
      >
        Add Careers
      </div>
      <div
        id={WrapperId}
        className={"FormWrapper " + `${data ? " " : "hide"}`}
        onClick={toggleForm}
      >
        <form
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={FormId}
          className="Form"
          onSubmit={(e) => {
            if (data) {
              formEditHandle(e);
            } else formSubmitHandle(e);
          }}
        >
          <div
            className="closeFormBtn"
            onClick={(e) => {
              e.stopPropagation();
              toggleForm();
            }}
          >
            <img src="/asset/close.png" width={20} alt="Close" />
          </div>
          <label className="mt-[100px]">Job name</label>
          <input type="text" name="job" required defaultValue={data?.job} />
          <label>City</label>
          <select name="city" id="">
            {city &&
              city.map((e) => (
                <option key={e._id} value={e._id}>
                  {e.name}
                </option>
              ))}
          </select>

          <label>Department</label>
          <select name="department" id="">
            {dpm &&
              dpm.map((e) => (
                <option key={e._id} value={e._id}>
                  {e.type}
                </option>
              ))}
          </select>

          <label>Contract</label>
          <select name="contract" id="">
            {contract &&
              contract.map((e) => (
                <option key={e._id} value={e._id}>
                  {e.type}
                </option>
              ))}
          </select>

          <label>Amount</label>
          <input
            type="number"
            name="Amount"
            required
            defaultValue={data?.amount}
          />
          <label>Open</label>
          <input
            type="text"
            name="Open"
            required
            defaultValue={data?.openString}
          />
          <label>Close</label>
          <input
            type="text"
            name="Close"
            required
            defaultValue={data?.closeString}
          />
          <label>Responsibilities</label>
          <div className="px-3 w-full ">
            <label>Job Description</label>
            <textarea
            form={FormId}
              className="w-[100%] ring-1 ring-[#254fc4] rounded-[5px] resize-none p-1"
              name="Responsibilities"
              required
              defaultValue={data ? data?.responsibilityDescription : ''}
            />
            <label>Task</label>
            <div className="px-[15px] w-full">
              <ul className="list-disc px-[15px] max-h-[120px] overflow-y-scroll">
                {taskList?.map((e, i) => (
                  <li className="my-[2px]" key={i} >
                    <div className="flex justify-between ">
                      <p className="w-full break-words">{e}</p>
                      <div
                        className="bg-[#f04545] cursor-pointer text-white px-[5px]"
                        onClick={() => {
                          setTaskList(
                            taskList.filter((e, index) => index !== i)
                          );
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full">
              <input type="text" name="Task" id="task" />
              <div
                form={FormId}
                className="mt-[5px] text-center w-full bg-[#254fc4] text-white rounded-[5px] "
                onClick={(e) => {
                  e.stopPropagation();
                  let addVal = document.getElementById("task").value;
                  if (addVal !== "") {
                    setTaskList([...taskList, addVal]);
                    document.getElementById("task").value = "";
                  }
                }}
              >
                Add
              </div>
            </div>
          </div>

          <label>Basic Qualifications</label>
          <div className="px-3 w-full ">
            <div className="px-[15px] w-full">
              <ul className="list-disc px-[15px] max-h-[120px] overflow-y-scroll">
                {qtyList?.map((e, i) => (
                  <li className="my-[2px]">
                    <div className="flex justify-between ">
                      <div>{e}</div>
                      <div
                        className="bg-[#f04545] cursor-pointer text-white px-[5px]"
                        onClick={() => {
                          setQtyList(qtyList.filter((e, index) => index !== i));
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full">
              <input type="text" name="Qualifications" id="qty" />
              <div
                className="mt-[5px] text-center w-full bg-[#254fc4] text-white rounded-[5px] "
                onClick={(e) => {
                  e.stopPropagation();
                  let addVal = document.getElementById("qty").value;
                  if (addVal !== "") {
                    setQtyList([...qtyList, addVal]);
                    document.getElementById("qty").value = "";
                  }
                }}
              >
                Add
              </div>
            </div>
          </div>

          <div className="wrap-submit">
            <input type="submit" />{" "}
          </div>
        </form>
      </div>
    </>
  );
};

export default CareersForm;
