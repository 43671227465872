import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Careers.department;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path), { cache: false });
  } catch (error) {}
};
export let add = async (type) => {
  try {
    return await axios.post(endpointBuilder(path), { type });
  } catch (error) {}
};
export let edit = async (id, type) => {
  try {
    return await axios.patch(endpointBuilder(path), { id, type });
  } catch (error) {
    
  }
};

export let remove = async (id) => {
  try {
    return await axios.delete(endpointBuilder(path + "?id=" + id));
  } catch (error) {}
};
