import "./App.css";
import logo from "./logo.png"
import Sidebar from "./components/Navigation/Sidebar/Sidebar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Solution from "./pages/Solution";
import Careers from "./pages/Careers";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useEffect, useState } from "react";

const clientID = process.env.REACT_APP_CLIENT_ID;
function App() {
  const [user, setuser] = useState();
  useEffect(() => {
    const initailClient = () => {
      gapi.client.init({
        clientID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initailClient);
  }, []);
  if (!user) {
    return (
      <div className="  flex flex-col justify-center items-center h-full">
        <img src={logo} alt="logo" width={220} />
        <h1 className="text-black">Please Sign in</h1>
        <GoogleLogin
        clientId={clientID}
        onSuccess={(res) => {
          setuser(res);
        }}
        cookiePolicy="single_host_origin"
        isSignedIn={true}
      />
      </div>
    );
  }
  return (
    <div className="App ">
      <div id="app-sidebar">
        <Sidebar user={user.profileObj.name} />
      </div>
      <div id="app-pages" className="pt-[30px]">
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="solution" element={<Solution />} />
            <Route path="careers" element={<Careers />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
