import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Contact.contact;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path),{ cache: false });
  } catch (error) {}
};
export let add = async () => {
  try {
    return await axios.post(endpointBuilder(path));
  } catch (error) {}
};
export let edit = async (id, building, address, email,tel, source) => {
  try {
    return await axios.patch(endpointBuilder(path),{id, building, address, email,tel, source});
  } catch (error) {}
};

export let remove = async (id) => {
  try {
    return await axios.delete(endpointBuilder(path));
  } catch (error) {}
};
