import React, { useEffect, useState } from "react";
import { getAll, onSave, remove } from "../../../controllers/Home/Partner";
import { API } from "../../../controllers/API_Object";
import "../index.css";
import PartnerForm from "./PartnerForm";
const { host } = API;
const Partner = () => {
  let [datas, setData] = useState();
  let [editData, seteditData] = useState();
  let [index, setIndex] = useState(0);
  let [isChange, setIsChange] = useState(false);
  if (!datas) {
    setTimeout(() => {
      getAll().then((res) => {
        setData(res?.data);
      });
    }, 20);
  }

  useEffect(() => {
    setIndex(datas?.length);
  }, [datas]);
  let refresh = () => {
    setData();
    seteditData();
  };

  return (
    <div>
      <h1 className="text-black ">Partners</h1>
      <div className="flex justify-end px-4 mb-2 gap-x-1">
        <button
          onClick={() => {
            onSave(datas).then(() => {
              setIsChange(false);
              refresh();
            });
          }}
          disabled={!isChange}
          className="w-[65px] h-[40px] bg-[#c47a25] text-white text-center disabled:opacity-50"
        >
          Save
        </button>
        <button
          onClick={() => {
            setIsChange(false);
            refresh();
          }}
          disabled={!isChange}
          className="w-[65px] h-[40px] bg-[#bd1717] text-white text-center disabled:opacity-50"
        >
          Cancel
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Logo (B&W)</th>
            <th>Logo (Color)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!datas &&
            datas
              ?.sort((e1, e2) => {
                return e1.index - e2.index;
              })
              .map((data, i) => (
                <tr key={i}>
                  <td>
                    <img
                      className="object-cover"
                      src={`${host}/file/${data.variant1}`}
                      alt={data.variant1}
                      width={data.width}
                      height={data.height}
                    />
                  </td>
                  <td>
                    <img
                      className=" object-cover"
                      src={`${host}/file/${data.variant2}`}
                      alt={data.variant2}
                      width={data.width}
                      height={data.height}
                    />
                  </td>
                  <td>
                    <div className="flex gap-x-[15px] justify-end">
                      <select
                        value={data.index}
                        onChange={(e) => {
                          let prev = data.index;
                          let next = parseInt(e.target.value);
                          if (prev - next === 1 || prev - next === -1) {
                            setData(
                              datas.map((e) => {
                                if (e.index === prev) {
                                  e.index = next;
                                } else if (e.index === next) {
                                  e.index = prev;
                                }
                                return e;
                              })
                            );
                          } else {
                            if (prev > next) {
                              setData(
                                datas.map((e) => {
                                  if (e.index < prev && e.index >= next) {
                                    e.index++;
                                  } else if (e.index === prev) {
                                    e.index = next;
                                  }
                                  return e;
                                })
                              );
                            } else if (prev < next) {
                              setData(
                                datas.map((e) => {
                                  if (e.index <= next && e.index > prev) {
                                    e.index--;
                                  } else if (e.index === prev) {
                                    e.index = next;
                                  }
                                  return e;
                                })
                              );
                            }
                          }
                          setIsChange(true);
                        }}
                        name=""
                        id=""
                        className="bg-transparent w-[45px]"
                      >
                        {Array.from({ length: index }, (_, i) => i + 1).map(
                          (e) => (
                            <option
                              key={e}
                              value={e}
                              className="bg-white text-black"
                            >
                              {e}
                            </option>
                          )
                        )}
                      </select>
                      <button
                        onClick={() => {
                          seteditData(data);
                        }}
                      >
                        <img
                          className="object-cover"
                          src="/asset/pencil.png"
                          alt="Edit"
                          width={20}
                        />
                      </button>
                      <button
                        onClick={() => {
                          remove(data?._id, data.index, datas).then(() =>
                            refresh()
                          );
                        }}
                      >
                        <img src="/asset/bin.png" alt="Delete" width={20} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
      <div>
        <PartnerForm data={editData} refresh={refresh} />
      </div>
    </div>
  );
};

export default Partner;
