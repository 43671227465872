import React, { useState } from "react";
import {  getAll } from "../../../controllers/Contact/Addr";
import AddrForm from "./AddrForm";

const Addr = () => {
  let [data, setData] = useState();
  let [editData, setEditdata] = useState();

  if (!data) {
    setTimeout(() => {
      getAll().then((res) => {
        setData(res?.data[0]);
      });
    }, 50);
  }
  let refresh = () => {
    setEditdata();
    setData();
  };
  return (
    <div>
      <h1 className="text-black ">Contact</h1>
      <div className="flex flex-col items-center  my-2">
        <h1 className=" bg-base p-2 rounded-sm">Map preview</h1>
        <iframe
          src={data?.source.split('"')[1]}
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <table>
        <tbody>
          <tr>
            <th className="w-[20%]">Building:</th>
            <td>{data?.building}</td>
          </tr>
          <tr>
            <th>Address:</th>
            <td>{data?.address}</td>
          </tr>
          <tr>
            <th>Email:</th>
            <td>{data?.email}</td>
          </tr>
          <tr>
            <th>Tel:</th>
            <td>{data?.tel}</td>
          </tr>
          <tr>
            <th>Google Map:</th>
            <td>{data?.source}</td>
          </tr>
        </tbody>
      </table>
      
      <div
        onClick={() => {
          setEditdata(data);
        }}
      >
        <AddrForm id={data?._id} refresh={refresh} data={editData} />
      </div>
      <div className="flex flex-col items-center">
        <h1  className="text-black ">ขั้นตอนการแก้ไขแผนที่</h1>
        <h4  className="text-black ">1.เมื่อเลือกสถานที่แล้วให้กดปุ่มแชร์</h4>
        <img src="/asset/ex1.png" alt="" width={400} />
        <h4  className="text-black ">2.เลือก Tab ผังแผนที่</h4>
        <h4  className="text-black ">3.คลิ๊กปุ่ม "คัดลอก HTML"</h4>
        <img src="/asset/ex2.png" alt="" width={400}/>
        <h4  className="text-black ">4.นำมาแก้ไขข้อมูลในช่อง Google Map</h4>
      </div>
    </div>
  );
};

export default Addr;
