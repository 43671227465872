import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Careers.city;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path), { cache: false });
  } catch (error) {}
};
export let add = async (city) => {
  try {
    return await axios.post(endpointBuilder(path), { name: city });
  } catch (error) {}
};
export let edit = async (id, name) => {
  try {
    return await axios.patch(endpointBuilder(path), { id, name });
  } catch (error) {}
};

export let remove = async (id) => {
  try {
    return await axios.delete(endpointBuilder(path + "?id=" + id));
  } catch (error) {}
};
