import React from "react";
import AboutMemberForm from "./components/AboutMemberForm";
import AboutMember from "./components/AboutMember";

const About = () => {
  return (
    <div>
      <AboutMember/>
    </div>
  );
};

export default About;
