import { useEffect, useState } from "react";
import "../index.css";
import { imgSource } from "../../../controllers/API_Object";
import { add, edit } from "../../../controllers/Home/Partner";
const PartnerForm = ({ data, refresh }) => {
  let [img1, setImg1] = useState();
  let [img2, setImg2] = useState();
  let [w, setW] = useState("60");
  let [h, setH] = useState("auto");
  // let [loading, setLoading] = useState(false);
  const WrapperId = "PartnerFormWrapper";
  const FormId = "PartnerForm";

  useEffect(() => {
    if (data) {
      if (data.height) {
        setH(data.height);
      }
      if (data.width) {
        setH(data.width);
      }
      setImg1(imgSource + data.variant1);
      setImg2(imgSource + data.variant2);
    }
  }, [data]);
  let toggleForm = () => {
    setH("auto");
    setW("120");
    setImg1();
    setImg2();
    refresh();
    document.getElementById(WrapperId).classList.toggle("hide");
    document.getElementById(FormId).reset();
  };
  let formSubmitHandle = (e) => {
    e.preventDefault();
    let formData = new FormData();
    let formData2 = new FormData();

    formData.append("file", e.target.v1.files[0]);
    formData2.append("file", e.target.v2.files[0]);
    add(formData, formData2, e.target.width.value, e.target.height.value).then(
      () => {
        toggleForm();
      }
    );
  };
  let formEditHandle = async (e) => {
    e.preventDefault();
    let formData = e.target.v1.files[0] ? new FormData() : undefined;
    let formData2 = e.target.v2.files[0] ? new FormData() : undefined;

    formData?.append("file", e.target.v1.files[0]);
    formData2?.append("file", e.target.v2.files[0]);
    await edit(
      data?._id,
      formData,
      formData2,
      e.target.width.value,
      e.target.height.value,
      data
    ).then(() => {
      toggleForm();
    });
  };
  return (
    <>
      <div
        className="addBtn flex h-100% justify-center items-center bg-[#254fc4] text-white h-[35px] cursor-pointer hover:bg-[#8445c3]"
        onClick={() => {
          document.getElementById(WrapperId).classList.toggle("hide");
        }}
      >
        Add Partner
      </div>
      <div
        id={WrapperId}
        className={`FormWrapper ${data ? " " : "hide"}`}
        onClick={toggleForm}
      >
        <form
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={FormId}
          className="Form"
          onSubmit={(e) => {
            if (data) {
              formEditHandle(e);
            } else formSubmitHandle(e);
          }}
        >
          <div
            className="closeFormBtn"
            onClick={(e) => {
              e.stopPropagation();
              toggleForm();
            }}
          >
            <img src="/asset/close.png" width={20} alt="Close" />
          </div>
          <label>Logo Black & White</label>
          {img1 && (
            <img src={img1} alt="" className="my-2" width={w} height={h} />
          )}
          <input
            required={!data ? true : false}
            type="file"
            name="v1"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              setImg1(URL.createObjectURL(e.target.files[0]));
            }}
          />
          <p className="mb-3 text-slate-600">type: png, jpeg</p>
          <label>Logo Color</label>
          {img2 && (
            <img src={img2} alt="" className="my-2" width={w} height={h} />
          )}
          <input
            required={!data ? true : false}
            type="file"
            name="v2"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              setImg2(URL.createObjectURL(e.target.files[0]));
            }}
          />
          <label htmlFor="">Image width</label>
          <input
            required={!data ? true : false}
            type="text"
            name="width"
            value={w}
            onChange={(e) => {
              setW(e.target.value);
            }}
          />
          <p className="text-slate-600">
            Specify number or "auto" For example 60, auto
          </p>
          <p className="text-slate-600">type: png, jpeg</p>
          <label htmlFor="">Image height</label>
          <input
            required={!data ? true : false}
            type="text"
            name="height"
            value={h}
            onChange={(e) => {
              setH(e.target.value);
            }}
          />
          <p className="text-slate-600">
            recomend "auto", Specify only width
          </p>

          <div className="wrap-submit">
            <input type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default PartnerForm;
