import {useState} from "react";
import City from "./City";
import { Department } from "./Department";
import Contract from "./Contract";

const Dropdown = () => {
  let [showExtend, setShowExtend] = useState(false);
  return (
    <div className="flex gap-x-12">
      <City />
      <Department />
      <Contract />
    </div>
  );
};

export default Dropdown;
