import TagLineForm from "./components/TagLineForm";
import './index.css'
import Partner from "./components/Partner";
const Home = () => {
  return (
    <div className="main-container flex col gap-y-[40px]">
      <TagLineForm />
      <Partner />
    </div>
  );
};

export default Home;
