import axios from "axios";
import { API, endpointBuilder } from "../API_Object";

let path = API.path.Solution.solution;

export let getAll = async () => {
  try {
    return await axios.get(endpointBuilder(path), { cache: false });
  } catch (error) {}
};
export let getByGroupID = async (groupId) => {
  try {
    return await axios.get(
      endpointBuilder(path + "/GetByGroupId?groupId=" + groupId),
      { cache: false }
    );
  } catch (error) {}
};
export let add = async (file, groupId, topic, targetId, description) => {
  try {
    await axios
      .post(endpointBuilder("/upload"), file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        if (!!res?.data) {
          try {
            return await axios.post(endpointBuilder(path), {
              groupId,
              image: res.data.file,
              topic,
              targetId,
              description,
            });
          } catch (error) {
          }
        }
      });
  } catch (error) {}
};
export let edit = async (
  id,
  file,
  groupId,
  topic,
  targetId,
  description,
  old
) => {
  try {
    let res = file
      ? await axios
          .post(endpointBuilder("/upload"), file, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (res) => {
            await axios.delete(endpointBuilder("/file/" + old.image));
            return res;
          })
      : undefined;
    if (res || !file) {
      try {
        return await axios.patch(endpointBuilder(path), {
          id,
          groupId,
          image: res?.data.file,
          topic,
          targetId,
          description,
        });
      } catch (error) {}
    }
  } catch (error) {}
};

export let remove = async (id,img) => {
  try {
    await axios.delete(endpointBuilder("/file/" + img));
    return await axios.delete(endpointBuilder(path + "?id=" + id));
  } catch (error) {}
};
